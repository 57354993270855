import { emptySplitApi } from '../../app/api';
import { addFlashMessage } from '../flash-messages/flashMessagesSlice';
import { GetFeedListResponse, GetFeedRequest, IFeedPostResponse } from './feed-types';
import { resetFeedList } from './feedSlice';
import { feedModerationAPI } from '../feed-moderation/feedModerationAPI';
import { GetFeedModerationListRequest } from '../feed-moderation/types';

export const feedAPI = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getFeedContent: builder.query<GetFeedListResponse, GetFeedRequest>({
      query: (body) => ({
        url: `/ActivityFeed/profile-page/${body.profileID}`,
        params: {
          PageNumber: body.PageNumber,
          PageSize: body.PageSize,
          onlyOwnContent: body.onlyMyPosts,
          onlyPostsFromProfilePageId: body.userIdToLoad,
        },
      }),
      // keepUnusedDataFor: 150, // 2.5 min
      providesTags: (result, error, arg) => [
        {
          type: 'FeedPosts', profileID: arg.profileID, pageNumber: arg.PageNumber, onlyMyPosts: arg.onlyMyPosts,
        },
      ],
    }),

    deletePost: builder.mutation<any, { postID: number, moderationListRequest: GetFeedModerationListRequest }>({

      query(data) {

        const { postID } = data;

        return {
          url: `/ActivityFeed/post/${postID}`,
          method: 'DELETE',
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchFeedModerationResult = dispatch(
          feedModerationAPI.util.updateQueryData('getFeedList', args.moderationListRequest, (draft) => ({
            ...draft,
            data: draft.data.filter((post: IFeedPostResponse) => post.data.id !== args.postID),
          })),
        );

        try {
          await queryFulfilled;
          dispatch(resetFeedList());
          dispatch(addFlashMessage('success', 'Post was deleted'));
        } catch {
          patchFeedModerationResult.undo();
          dispatch(addFlashMessage('error', 'Post cannot be deleted. Please try again.'));
        }
      },

      invalidatesTags: (result, error, args) => [
        'FeedPosts', 'FeedModerationList',
      ],
    }),

    // todo: move to file API
    getFileFromAzure: builder.mutation<any, string>({
      query: (guid) => ({
        method: 'GET',
        url: `/AzureStorage/attachment/${guid}`,
      }),
    }),
  }),
});

export const {
  useGetFeedContentQuery,
  useDeletePostMutation,
  useGetFileFromAzureMutation,
} = feedAPI;
